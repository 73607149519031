import UserInfoHeader from "../user-info/components/user-info-header/index.vue";
import ManagerItem from "./components/manager-item/index.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Managers",
    data() {
        return {
            hasNextPage: false,
            loadMoreLoading: false,
            currentPage: 1,
        }
    },
    components: {
        UserInfoHeader,
        ManagerItem
    },
    computed: {
        ...mapGetters({
            managerList: "managers/managerList",
            managerListLoading: "managers/managerListLoading",
        })
    },
    created() {
        this.fetchManagers().then(response => {
            this.hasNextPage = Boolean(response.links.next)
        });
    },
    methods: {
        ...mapActions({
            fetchManagers: "managers/GET_MANAGERS",
            fetchManagersNextPage: "managers/GET_MANAGERS_NEXT_PAGE"
        }),

        loadMore() {
            this.loadMoreLoading = true;
            this.currentPage++;
            this.fetchManagersNextPage(this.currentPage).then(response => {
                this.hasNextPage = Boolean(response.links.next)
            })
            .finally(() => {
                this.loadMoreLoading = false;
            })
        }
    }
}
